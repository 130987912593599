'use client';

import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import GoBuid from '@/assets/icons/GoBuid-logo.svg';
import Link from 'next/link';
import DesktopNav from '@/app/[lang]/(web)/_components/DesktopNav';
import { BlogData } from '@/lib/blogs/interface';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { useMediaQuery } from '@mui/material';
import { useScroll } from 'framer-motion';
import NavButtons from './NavButtons';
import UserProfileMenu from './UserProfileMenu';
import { useSelfProfileQuery } from '@/lib/users/usersAPI';
import { useRouter } from 'next/navigation';
import { setCurrentGroupId } from '@/lib/users/userSlice';

const MobileNav = dynamic(
  () => import('@/app/[lang]/(web)/_components/MobileNav'),
  {
    ssr: false,
  },
);

const LanguageSwitcher = dynamic(
  () => import('@/components/UI/LanguageSwitcher'),
);

export interface NavItem {
  id: number;
  text: string;
  href: string;
  description?: string;
}

export interface NavSection {
  id: number;
  text: string;
  linkList: NavItem[];
}

const navList: NavSection[] = [
  {
    id: 1,
    text: 'navProduct',
    linkList: [
      {
        id: 1,
        text: 'navFeatureOverview',
        description: 'navFeatureOverviewDes',
        href: '/features',
      },
      {
        id: 2,
        text: 'navWorkforce',
        description: 'navWorkforceDes',
        href: '/features/features-help',
      },
      {
        id: 3,
        text: 'navEquipment',
        description: 'navEquipmentDes',
        href: '/features/features-help/equipment',
      },
      {
        id: 4,
        text: 'navProgress',
        description: 'navProgressDes',
        href: '/features/features-help/progress',
      },
      {
        id: 5,
        text: 'navBudget',
        description: 'navBudgetDes',
        href: '/features/features-help/budget',
      },
      {
        id: 6,
        text: 'navMissionControl',
        description: 'navMissionControlDes',
        href: '/features/features-help/mission-control',
      },
      {
        id: 7,
        text: 'navForm',
        description: 'navFormDes',
        href: '/features/features-help/form',
      },
    ],
  },
  {
    id: 2,
    text: 'navWhyUs',
    linkList: [
      {
        id: 1,
        text: 'navWhyGoBuid',
        href: '/whygobuid',
      },
      {
        id: 2,
        text: 'navCustomerStories',
        href: '/customer-story',
      },
      {
        id: 3,
        text: 'navOurStory',
        href: '/ourstory',
      },
    ],
  },
  {
    id: 3,
    text: 'navResources',
    linkList: [
      {
        id: 1,
        text: 'navBlog',
        href: '/blog',
      },
      {
        id: 2,
        text: 'navHelpCenter',
        href: 'https://help.gobuid.com/',
      },
      // TODO 未實現video tutorial
      // {
      //   id: 3,
      //   text: 'navVideoTutorials',
      //   href: '/',
      // },
    ],
  },
];

interface NavbarProps {
  t: any;
  blogData: BlogData;
  lang: string;
}

const Navbar = ({ t, blogData, lang }: NavbarProps) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { profile } = useAppSelector((state) => state.user);
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const { scrollY } = useScroll();
  const [scrollStyles, setScrollStyles] = useState({
    top: '0px',
    left: '0px',
    right: '0px',
    inset: '0px',
    borderRadius: '0px',
    backgroundColor: '#FEFEFE',
    backdropFilter: 'blur(0px)',
    padding: '0px',
  });

  const [token, setToken] = useState(null);

  const handleToggleChange = () => {
    setIsActive(!isActive);
    const newMobileNavState = !isMobileNavOpen;
    setIsMobileNavOpen(newMobileNavState);
  };

  const { data } = useSelfProfileQuery(
    {
      token,
    },
    {
      skip: !token,
    },
  );

  useEffect(() => {
    if (!data) return;
    if (!data.isRegisterCompleted) {
      router.push(`/${lang}/signup/profile`);
    }
  }, [data]);

  useEffect(() => {
    if (isDesktop) {
      setIsMobileNavOpen(false);
    }
  }, [isDesktop]);

  useEffect(() => {
    const unsubscribe = scrollY.on('change', (latest) => {
      if (isDesktop) {
        if (latest > 0) {
          setScrollStyles({
            borderRadius: '16px',
            top: '20px',
            left: '20px',
            right: '20px',
            inset: '20px',
            backgroundColor: '#FEFEFEE5',
            backdropFilter: 'blur(200px)',
            padding: '0px 20px',
          });
        } else {
          setScrollStyles({
            borderRadius: '0px',
            top: '0px',
            left: '0px',
            right: '0px',
            inset: '0px',
            backgroundColor: '#FEFEFE',
            backdropFilter: 'blur(0px)',
            padding: '0px',
          });
        }
      }
    });
    return () => unsubscribe();
  }, [scrollY, isDesktop]);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken && typeof window !== 'undefined') {
      setToken(storedToken);
    }
  }, []);

  useEffect(() => {
    const localGroupId = localStorage.getItem('groupId');
    if (localGroupId && typeof window !== 'undefined') {
      dispatch(setCurrentGroupId(localGroupId));
    }
  }, []);

  return (
    <header
      className="sticky left-0 top-0 z-50 mx-auto w-full max-w-[1280px] transition-all duration-300"
      style={{
        borderRadius: scrollStyles.borderRadius,
        padding: scrollStyles.padding,
        top: scrollStyles.top,
        left: scrollStyles.left,
        right: scrollStyles.right,
        inset: scrollStyles.inset,
      }}
    >
      <div
        className="flex h-[64px] items-center p-4 lg:h-[72px]"
        style={{
          borderRadius: scrollStyles.borderRadius,
          backgroundColor: scrollStyles.backgroundColor,
          backdropFilter: scrollStyles.backdropFilter,
        }}
      >
        <Link href={`/${lang}`} aria-label="Go to GoBuid homepage">
          <GoBuid
            width={100}
            height={24}
            media="svg"
            className="h-5 w-[82px] text-primary-900 lg:h-6 lg:w-[86px]"
            aria-hidden="true"
          />
        </Link>
        <div className="ml-[28px] hidden lg:block">
          <DesktopNav navList={navList} blogData={blogData} t={t} lang={lang} />
        </div>
        <div className="flex w-full items-center justify-end gap-3 pr-2 lg:pr-0">
          <div className="flex items-center gap-3">
            <LanguageSwitcher />
            <div className="hidden lg:block">
              <NavButtons t={t} />
            </div>
          </div>

          {profile.userId && profile.userId !== 0 ? (
            <UserProfileMenu profile={profile} />
          ) : null}
          <button
            type="button"
            className={`menu-icon lg:hidden ${isMobileNavOpen ? 'active' : ''}`}
            onClick={handleToggleChange}
            aria-label="Menu"
          >
            <div className="line-1" />
            <div className="line-2" />
            <div className="line-3" />
          </button>
        </div>
      </div>
      {!isDesktop && (
        <MobileNav
          isMobileNavOpen={isMobileNavOpen}
          setIsMobileNavOpen={setIsMobileNavOpen}
          navList={navList}
          blogData={blogData}
          t={t}
          lang={lang}
        />
      )}
    </header>
  );
};

export default Navbar;
