'use client';

import useCheckMobileAgent from '@/hooks/useCheckMobileAgent';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { createSelector } from '@reduxjs/toolkit';
import Link from 'next/link';
import { useParams } from 'next/navigation';

const selectSubscriptionStatus = createSelector(
  [(state) => state.user.profile.groups, (state) => state.user.currentGroupId],
  (groups, currentGroupId) => {
    if (!groups || !currentGroupId) return false;

    const currentGroup = groups.find(
      (group) => group.groupId === Number(currentGroupId),
    );
    if (!currentGroup || !currentGroup.subscriptionExpiredDate) return false;

    const expiredDate = new Date(currentGroup.subscriptionExpiredDate);
    const now = new Date();

    return expiredDate > now;
  },
);

const NavButton = ({
  href,
  text,
  className,
}: {
  href: string;
  text: string;
  className?: string;
}) => {
  return (
    <Link
      href={href}
      className={`w-full text-nowrap rounded-[100px] px-4 py-[10px] text-center transition-all duration-300 ease-out ${className}`}
    >
      {text}
    </Link>
  );
};

const getNavLinks = (
  isMobile: boolean,
  lang: string,
  currentGroupId: number,
) => ({
  lanuchGoBuid: isMobile ? `gobuidapp://` : `/${lang}/app`,
  signup: `/${lang}/signup`,
  login: `/${lang}/login`,
  managePlan: currentGroupId
    ? `/${lang}/admin/${currentGroupId}/plans&receipts`
    : '',
  upgrade: currentGroupId ? `/${lang}/admin/${currentGroupId}/chooseplan` : '',
});

const NavButtons = ({ t }: { t: any }) => {
  const params = useParams();
  const { lang } = params as { lang: string };
  // 1. 檢查裝置是否為手機
  const isMobile = useCheckMobileAgent();
  // 2. 判斷是否有登入
  const { email, isRegisterCompleted } = useAppSelector(
    (state) => state.user.profile,
  );
  const { currentGroupId } = useAppSelector((state) => state.user);
  const isLoggedIn = !!email;
  // 3. 判斷當前的group是否是pay group
  const hasValidSubscription = useAppSelector(selectSubscriptionStatus);

  const links = getNavLinks(isMobile, lang, currentGroupId);

  const renderButtons = () => {
    if (!isLoggedIn || !isRegisterCompleted) {
      // 未登入用戶顯示登入和註冊按鈕
      return (
        <div className="typo-label-sm flex flex-col items-center gap-2 lg:flex-row-reverse">
          <NavButton
            href={links.signup}
            text={t.getStarted}
            className="bg-primary-900 text-white"
          />
          <NavButton
            href={links.login}
            text={t.login}
            className="border border-gray-300"
          />
        </div>
      );
    }
    if (hasValidSubscription) {
      return (
        <div className="typo-label-sm flex flex-col items-center gap-2 lg:flex-row-reverse">
          <NavButton
            href={links.lanuchGoBuid}
            text={t.navLaunchButton}
            className="bg-primary-900 text-white"
          />
          <NavButton
            href={links.managePlan}
            text={t.navManagePlan}
            className="border border-gray-300"
          />
        </div>
      );
    } else {
      return (
        <div className="typo-label-sm flex flex-col items-center gap-2 lg:flex-row-reverse">
          <NavButton
            href={links.upgrade}
            text={t.navUpgrade}
            className="bg-secondary-500"
          />
          <NavButton
            href={links.lanuchGoBuid}
            text={t.navLaunchButton}
            className="border border-gray-300 lg:border-none"
          />
        </div>
      );
    }
  };

  return <div>{renderButtons()}</div>;
};

export default NavButtons;
