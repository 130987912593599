'use client';

import { useEffect, useState } from 'react';
import i18next, { KeyPrefix } from 'i18next';
import {
  initReactI18next,
  UseTranslationOptions,
  useTranslation as useTranslationOrg,
} from 'react-i18next';
import { useCookies } from 'react-cookie';
import resourcesToBackend from 'i18next-resources-to-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Lang } from '@/types/lang';
import { getOptions, languages, cookieName } from './settings';
import { enhancedGetTranslation, NestedKeyOf } from './i18nHelpers';

const runsOnServerSide = typeof window === 'undefined';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(
    resourcesToBackend(
      (lng, ns) => import(`/public/locales/${lng}/${ns}.json`),
    ),
  )
  .init({
    ...getOptions(),
    lng: undefined, // let detect the language on client side
    detection: {
      order: ['path', 'htmlTag', 'cookie', 'navigator'],
    },
    preload: runsOnServerSide ? languages : [],
  });

type NS = 'common';

export function useTranslation(
  lng: any,
  ns: NS,
  options?: UseTranslationOptions<KeyPrefix<NS>>,
) {
  const [cookies, setCookie] = useCookies([cookieName, 'i18next']);
  const ret = useTranslationOrg(ns, options);
  const { i18n, t: originalT } = ret;

  if (runsOnServerSide && lng && i18n.resolvedLanguage !== lng) {
    i18n.changeLanguage(lng);
  } else {
    const [activeLng, setActiveLng] = useState(i18n.resolvedLanguage);

    useEffect(() => {
      if (activeLng === i18n.resolvedLanguage) return;
      setActiveLng(i18n.resolvedLanguage);
    }, [activeLng, i18n.resolvedLanguage]);

    useEffect(() => {
      if (!lng || i18n.resolvedLanguage === lng) return;
      i18n.changeLanguage(lng);
    }, [lng, i18n]);

    useEffect(() => {
      if (cookies.i18next === lng) return;
      setCookie(cookieName, lng, { path: '/' });
    }, [lng, cookies.i18next, setCookie]);
  }

  // wrap t function
  const t = <T extends NestedKeyOf<Lang>>(key: T, options?: any) =>
    enhancedGetTranslation(originalT, key, options);
  return {
    ...ret,
    t,
  };
}
