'use client';

import { useTranslation } from '@/app/i18n/client';
import BaseIconButton from '@/components/base/BaseButton/BaseIconButton';
import { BaseIcon } from '@/components/base/BaseIcon/BaseIcon';
import { useLogout } from '@/hooks/useLogout';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { setCurrentGroupId } from '@/lib/users/userSlice';
import { useParams } from 'next/navigation';
import React from 'react';

interface CompanySwitcherProps {
  onClose: () => void;
  onSwitchCompany?: (groupId: number) => void;
  onNavigateToLogin?: () => void;
}

const CompanySwitcher = ({
  onClose,
  onNavigateToLogin,
  onSwitchCompany,
}: CompanySwitcherProps) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const { lang } = params;
  const { t } = useTranslation(lang, 'common');
  const { groups } = useAppSelector((state) => state.user.profile);
  const { currentGroupId } = useAppSelector((state) => state.user);

  const handleSwitchClick = (groupId) => {
    onSwitchCompany?.(groupId);
    dispatch(setCurrentGroupId(groupId));
    localStorage.setItem('groupId', groupId);
    localStorage.setItem('lastGroupId', groupId);
    onClose();
  };

  const logout = useLogout();
  const handleLogout = async () => {
    onClose();
    await logout();
    onNavigateToLogin?.();
  };

  return (
    <div className="w-[343px]">
      <div className="flex items-center border-b-1 border-gray-300 p-4">
        <p className="typo-label-xxl flex-1">
          {t('officialMarketing.switchAccount')}
        </p>
        <BaseIconButton
          name="CloseDefault"
          onClick={onClose}
          size="xs"
          variant="text"
          className="absolute right-4"
        />
      </div>
      <div className="w-full">
        {groups
          .filter((group) => group.roleId === 1 || group.roleId === 2)
          .map((group) => (
            <button
              type="button"
              key={group.groupId}
              onClick={() => handleSwitchClick(group.groupId)}
              className={`${
                Number(currentGroupId) === group.groupId
                  ? 'bg-primary-100'
                  : 'bg-white hover:bg-gray-100'
              } flex w-full items-center px-4 py-3 text-start`}
            >
              <div className="flex flex-1 flex-col justify-center">
                <p className="typo-label-md line-clamp-1">{group.groupName}</p>
                <p className="typo-paragraph-sm text-gray-800">
                  {group.roleId === 1 &&
                    t('officialMarketing.switchAccountOwner')}
                  {group.roleId === 2 &&
                    t('officialMarketing.switchAccountCoOwner')}
                </p>
              </div>
              {Number(currentGroupId) === group.groupId && (
                <div className="shrink-0">
                  <BaseIcon name="CheckDefault" size={16} color="#262D42" />
                </div>
              )}
            </button>
          ))}
      </div>
      <button
        className="flex w-full items-center gap-5 border-t-1 border-gray-300 p-4 hover:bg-gray-100"
        onClick={handleLogout}
      >
        <BaseIcon name="LogOutDefault" size={24} />
        <p className="typo-label-md">
          {t('officialMarketing.switchAccountLogout')}
        </p>
      </button>
    </div>
  );
};

export default CompanySwitcher;
