import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import clsx from 'clsx';
import { BaseIcon } from '../BaseIcon/BaseIcon';
import { IconName } from '../BaseIcon/interface';

interface BaseIconButtonProps
  extends Omit<ButtonProps, 'variant' | 'size' | 'color'> {
  name: IconName;
  variant?: 'text' | 'outline' | 'fill';
  color?: string;
  disabled?: boolean;
  loading?: boolean;
  size?: 'xs' | 's' | 'm' | 'l';
  className?: string;
  iconColor?: string;
}

const classNameObj = {
  base: 'font-semibold text-center rounded-full min-w-2 border border-solid border-transparent',
  variants: {
    fill: 'bg-gray-100 hover:bg-gray-100',
    outline: 'bg-white  hover:bg-white text-black border border-solid !border-gray-300',
    text: 'bg-[initial] hover:bg-white text-black ',
  },
  states: {
    hover: {
      fill: 'hover:shadow-[0_0_999px_0_#0008210A_inset]',
      outline:
        'hover:shadow-[0_0_999px_0_#0008210A_inset] hover:border-gray-600',
      text: 'hover:shadow-[0_0_999px_0_#0008210A_inset] hover:border-transparent',
    },
    focus: {
      fill: 'focus:bg-primary-focus focus:border focus:border-solid focus:border-primary-400',
      outline:
        'focus:bg-black-focus focus:border focus:border-solid focus:border-primary-400',
      text: 'focus:bg-black-focus focus:border focus:border-solid focus:border-primary-400',
    },
    pressed: {
      fill: 'pressed:shadow-[0_0_999px_0_#0029A51F_inset]',
      outline:
        'pressed:shadow-[0_0_999px_0_#0029A51F_inset] pressed:border pressed:border-solid pressed:border-[##BFC1C7]',
      text: 'pressed:shadow-[0_0_999px_0_#0029A51F_inset]',
    },
    disabled: 'opacity-50 cursor-not-allowed',
    loading: 'bg-gray-200 text-gray-400 cursor-wait',
  },
  sizes: {
    xs: 'p-1 leading-tight size-7',
    s: 'p-2 leading-[1.4] size-9',
    m: 'p-3 leading-normal size-12',
    l: 'p-4 leading-relaxed size-14',
  },
};

const BaseIconButton: React.FC<BaseIconButtonProps> = ({
  variant = 'fill',
  color,
  disabled,
  loading,
  size = 'm',
  className,
  name,
  iconColor,
  ...props
}) => {
  const baseClasses = classNameObj.base;
  const variantClasses = classNameObj.variants[variant];
  const hoverClasses = classNameObj.states.hover[variant];
  const focusClasses = classNameObj.states.focus[variant];
  const disabledClasses = disabled ? classNameObj.states.disabled : '';
  const loadingClasses = loading ? classNameObj.states.loading : '';
  const sizeClasses = classNameObj.sizes[size];
  const iconSize = {
    xs: 16,
    s: 16,
    m: 24,
    l: 24,
  };

  return (
    <Button
      className={clsx(
        baseClasses,
        variantClasses,
        hoverClasses,
        focusClasses,
        disabledClasses,
        loadingClasses,
        sizeClasses,
        className,
      )}
      disabled={disabled}
      {...props}
    >
      <BaseIcon name={name} size={iconSize[size]} color={iconColor} />
    </Button>
  );
};

export default BaseIconButton;
