import { useLogoutMutation } from '@/lib/users/usersAPI';
import { cleanLocalStorage } from '@/utils/cleanlocalStorage';

export function useLogout() {
  const [logout] = useLogoutMutation();

  return async () => {
    try {
      await logout({});
      cleanLocalStorage();
    } catch (error) {
      console.error(error);
    }
  };
}
