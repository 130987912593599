import { Popover, PopoverOrigin, PopoverProps } from '@mui/material';
import React, { useState, ReactElement, CSSProperties } from 'react';

/**
 * Props for BasePopover
 * children: The content of the popover
 * buttonChildren: The content of the button
 * popoverProps: The props for the Popover
 * popoverProps has the following properties: anchorOrigin, transformOrigin
 * anchorOrigin has the following properties: vertical, horizontal
 * transformOrigin has the following properties: vertical, horizontal
 * buttonStyle: The style of the button
 * buttonClassName: The className of the button
 * onClick: The function to be called when the button is clicked
 * onClose: The function to be called when the popover is closed
 */
interface BasePopoverProps {
  children: (closePopover: () => void) => ReactElement<any> | null;
  buttonChildren: ReactElement<any>;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  popoverProps?: Partial<PopoverProps>;
  buttonStyle?: CSSProperties;
  buttonClassName?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onClose?: () => void;
}

const BasePopover: React.FC<BasePopoverProps> = ({
  children,
  buttonChildren,
  anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
  transformOrigin = { vertical: 'top', horizontal: 'right' },
  popoverProps,
  buttonStyle,
  buttonClassName,
  onClick,
  onClose,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    if (onClick) onClick(event);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (onClose) onClose();
  };

  const open = Boolean(anchorEl);

  const buttonWithClick = React.cloneElement(buttonChildren, {
    onClick: handleClick,
    style: buttonStyle,
    className: buttonClassName,
  });

  const popoverContent = children(handleClose);

  return (
    <>
      {buttonWithClick}
      {popoverContent !== null && (
        <Popover
          open={open}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          anchorEl={anchorEl}
          onClose={handleClose}
          {...popoverProps}
          sx={{
            '.MuiPopover-paper': {
              padding: '8px 0px',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '12px',
              ...popoverProps?.sx?.['.MuiPopover-paper'],
            },
            ...popoverProps?.sx,
          }}
        >
          {popoverContent}
        </Popover>
      )}
    </>
  );
};

export default BasePopover;
