'use client';

import BaseAvatar from '@/components/base/BaseAvatar/BaseAvatar';
import BasePopover from '@/components/base/BasePopover/BasePopover';
import { useMediaQuery } from '@mui/material';
import React from 'react';
import CompanySwitcher from './CompanySwitcher';
import BaseTooltip from '@/components/base/BaseTooltip/BaseTooltip';
import { useAppSelector } from '@/lib/hooks';
import { createSelector } from '@reduxjs/toolkit';

interface UserProfileMenuProps {
  profile: any;
  onNavigateToLogin?: () => void;
  onSwitchCompany?: (groupId: number) => void;
}

const selectCurrentGroup = createSelector(
  [(state) => state.user.profile.groups, (state) => state.user.currentGroupId],
  (groups, currentGroupId) =>
    groups.find((group) => group.groupId === Number(currentGroupId)),
);

const UserProfileMenu = ({
  profile,
  onNavigateToLogin,
  onSwitchCompany,
}: UserProfileMenuProps) => {
  const isDesktopQuery = useMediaQuery('(max-width: 1024px)');
  const currentGroup = useAppSelector(selectCurrentGroup);

  if (!profile.isRegisterCompleted) {
    return null;
  }

  return (
    <BasePopover
      buttonChildren={
        <div>
          <BaseTooltip
            tooltipTitle={
              <div>
                <p className="typo-label-sm">{profile.email}</p>
                <p className="typo-paragraph-sm text-gray-500">
                  {currentGroup?.groupName}
                </p>
              </div>
            }
          >
            <button
              className="peer h-6 w-6 cursor-pointer lg:h-10 lg:w-10"
              type="button"
            >
              <BaseAvatar
                fullName={profile.fullName}
                nameAbbreviation={profile.nameAbbreviation}
                abbreviationBgColor={profile.abbreviationBgColor}
                photoUrl={profile.photoUrl}
                size={isDesktopQuery ? 24 : 40}
              />
            </button>
          </BaseTooltip>
        </div>
      }
      buttonClassName="flex items-center justify-center"
      popoverProps={{
        sx: {
          '.MuiPopover-paper': {
            padding: '0px',
            borderRadius: '24px',
          },
        },
      }}
    >
      {(closePopover) => (
        <CompanySwitcher
          onClose={closePopover}
          onNavigateToLogin={onNavigateToLogin}
          onSwitchCompany={onSwitchCompany}
        />
      )}
    </BasePopover>
  );
};

export default UserProfileMenu;
