import { TFunction } from 'i18next';
import CommonType from '../../../public/locales/en/common.json';

export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

export function enhancedGetTranslation<
  T extends NestedKeyOf<typeof CommonType>,
>(t: TFunction, key: T, option): any {
  return t(key as keyof typeof CommonType, option);
}
