import { Fade, Tooltip } from '@mui/material';
import React from 'react';

interface BaseToolTipProps {
  children: React.ReactElement<any, any>;
  tooltipTitle: React.ReactNode;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  placement?:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'left-start'
    | 'left-end'
    | 'right-start'
    | 'right-end';
  disableTouchListener?: boolean;
}

const BaseTooltip: React.FC<BaseToolTipProps> = ({
  children,
  tooltipTitle,
  placement = 'top',
  size = 'md',
  disableTouchListener = false,
}) => {
  let paddingValue: string;

  if (size === 'xs') {
    paddingValue = '8px';
  } else if (size === 'sm') {
    paddingValue = '12px';
  } else if (size === 'md') {
    paddingValue = '16px';
  } else if (size === 'lg') {
    paddingValue = '20px';
  } else {
    paddingValue = '16px';
  }

  return (
    <Tooltip
      disableFocusListener
      placement={placement}
      title={tooltipTitle}
      enterDelay={200}
      enterNextDelay={200}
      enterTouchDelay={0}
      leaveTouchDelay={2000}
      arrow
      disableTouchListener={disableTouchListener}
      slots={{
        transition: Fade,
      }}
      slotProps={{
        transition: {
          timeout: 600,
        },
        tooltip: {
          sx: {
            bgcolor: '#000821',
            color: 'white',
            fontSize: '12px',
            maxWidth: '200px',
            fontWeight: 400,
            lineHeight: '16px',
            padding: paddingValue,
            borderRadius: '8px',
            '& .MuiTooltip-arrow': {
              color: '#000821',
            },
          },
        },
      }}
    >
      {React.isValidElement(children) ? children : <span>{children}</span>}
    </Tooltip>
  );
};

export default BaseTooltip;
