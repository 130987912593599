import { useState, useEffect } from 'react';

function useCheckMobileAgent() {
  const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const userAgent = navigator.userAgent || navigator.vendor;
      const newIsMobile =
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i.test(
          userAgent,
        );
      setIsMobile(newIsMobile);
    }
  }, []);

  return isMobile;
}

export default useCheckMobileAgent;
